import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ImLocation, ImPhone } from "react-icons/im"
import { IoIosTime } from "react-icons/io"

const Footer = () => {
  return (
    <>
      <footer className="footer p-10 mt-20 bg-base-200 text-base-content gap-10">
        <div className="col-span-2 w-full">
          <span className="footer-title flex items-center gap-2">
            <ImLocation /> Localización
          </span>
          <div>
            c/Princep de Bergara núm. 6, 08860 l'Hospitalet de LLobregat
          </div>
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2994.686077633261!2d2.0963028153560526!3d41.35917657926684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a49923a9a3b72d%3A0xfffe9d9d356c1165!2sCarrer%20Pr%C3%ADncep%20de%20Bergara%2C%206%2C%2008901%20L&#39;Hospitalet%20de%20Llobregat%2C%20Barcelona!5e0!3m2!1ses!2ses!4v1624798647342!5m2!1ses!2ses"
            className="w-full h-64"
            allowFullScreen={true}
            loading="lazy"
          ></iframe>
        </div>
        <div>
          <span className="footer-title flex items-center gap-2">
            <IoIosTime /> Horario
          </span>
          <div>
            <p>de martes a sábado</p>
            <p>11:00-14:30</p>
            <p>16:00-19:30</p>
            <p>lunes y domingo</p>
            <p>Cerrado</p>
          </div>
          <span className="footer-title flex items-center gap-2">
            <ImPhone /> Teléfono
          </span>
          <p>+34 638 015 991</p>
        </div>
      </footer>
    </>
  )
}

export default Footer
