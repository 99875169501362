import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AiFillStar } from "react-icons/ai"
import { SectionTitle } from "./ui"

interface Review {
  id: string
  author_name: string
  profile_photo_url: string
  text: string
}

interface GooglePlace {
  name: string
  rating: number
  user_ratings_total: number
  icon: string
}

type DataProps = {
  allGooglePlacesReview: {
    nodes: Review[]
  }
  googlePlacesPlace: GooglePlace
}

const Reviews = () => {
  const data = useStaticQuery<DataProps>(graphql`
    query ReviewsQuery {
      allGooglePlacesReview(filter: { rating: { eq: 5 } }) {
        nodes {
          id
          author_name
          profile_photo_url
          text
        }
      }
      googlePlacesPlace {
        name
        rating
        user_ratings_total
        icon
      }
    }
  `)

  return (
    <div className="min-w-screen min-h-screen flex justify-center py-5">
      <div className="w-full px-5 text-gray-800">
        <div className="w-full max-w-6xl mx-auto">
          <SectionTitle
            title="Reviews"
            subtitle="Lo que dicen nuestros clientes"
          />

          <div className="w-full mx-auto flex justify-center mb-5">
            <Stats place={data.googlePlacesPlace} />
          </div>

          <div className="sm:hidden carousel w-full space-x-6">
            {data.allGooglePlacesReview.nodes.map((review) => (
              <ReviewBox key={review.id} review={review} />
            ))}
          </div>

          <div className="hidden sm:grid grid-cols-2 lg:grid-cols-3 gap-3 justify-center">
            {data.allGooglePlacesReview.nodes.map((review) => (
              <ReviewBox key={review.id} review={review} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const ReviewBox = ({ review }: { review: Review }) => {
  return (
    <div className="carousel-item card w-72 shadow-xl mx-auto">
      <div className="card-body">
        <div className="flex justify-between">
          <div className="avatar w-1/4">
            <div className="scale-75 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
              <img src={review.profile_photo_url} alt={review.author_name} />
            </div>
          </div>
          <h2 className="w-3/4 card-title">{review.author_name}</h2>
        </div>
        <Stars />
        <p className="text-base text-gray-600 leading-tight">
          <span className="text-3xl leading-none italic font-bold text-gray-400 mr-1">
            "
          </span>
          {review.text}
          <span className="text-3xl leading-none italic font-bold text-gray-400 ml-1">
            "
          </span>
        </p>
      </div>
    </div>
  )
}

const Stars = () => (
  <div className="rating mx-auto">
    <input
      type="radio"
      name="rating-2"
      className="mask mask-star-2 bg-yellow-400"
    />
    <input
      type="radio"
      name="rating-2"
      className="mask mask-star-2 bg-yellow-400"
    />
    <input
      type="radio"
      name="rating-2"
      className="mask mask-star-2 bg-yellow-400"
    />
    <input
      type="radio"
      name="rating-2"
      className="mask mask-star-2 bg-yellow-400"
    />
    <input
      type="radio"
      name="rating-2"
      className="mask mask-star-2 bg-yellow-400"
      defaultChecked
    />
  </div>
)

const Stats = ({ place }: { place: GooglePlace }) => {
  return (
    <div className="stats shadow">
      <div className="stat">
        <div className="stat-figure text-warning text-3xl">
          <AiFillStar />
        </div>
        <div className="stat-title">Google Rating</div>
        <div className="stat-value text-secondary">{place.rating}/5</div>
        <div className="stat-desc">{place.user_ratings_total} reviews</div>
      </div>
    </div>
  )
}

export default Reviews
