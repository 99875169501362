import React from "react"
import tw, { css } from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import { ImageDataLike, getImage, GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Hero from "../components/hero"
import Features from "../components/features"
import Instagram from "../components/instagram"
import Reviews from "../components/reviews"
import Faqs from "../components/faqs"
import Footer from "../components/footer"
import Modal from "../components/modal"
import Icon from "../components/floating-icon"
import SEO from "../components/seo"

type DataProps = {
  file: ImageDataLike
}

const IndexPage = () => {
  const { file } = useStaticQuery<DataProps>(graphql`
  query GlobalPageQuery {
    file(base: {eq: "fondo web.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1000, 
          placeholder: BLURRED, 
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
  `)

  const image = getImage(file)
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      preserveStackingContext
    >

      <div className="h-screen overflow-x-hidden"

      >
        <Modal />
        <Hero />
        <Features />
        <Instagram />
        <Reviews />
        <Faqs />
        <Footer />
        <Icon />
      </div>
    </BackgroundImage>
  )
}

export const Head = () => <SEO />

export default IndexPage
