import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import { FluidObject } from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import { FaFacebookF, FaInstagram } from "react-icons/fa"

type DataProps = {
  mdx: {
    frontmatter: {
      title: string
      subhero: string
    }
    body: string
    id: string
  }
  file: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const Hero = () => {
  const data = useStaticQuery<DataProps>(graphql`
    query HeroQuery {
      mdx(slug: { eq: "hero" }) {
        frontmatter {
          title
          subhero
        }
        body
        id
      }
      file(base: { eq: "cabecera web.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      className="hero relative"
      Tag="div"
      fluid={data.file.childImageSharp.fluid}
      preserveStackingContext={false}
    >
      <SocialMedia />
      {/* <div className="hero-overlay bg-opacity-50 bg-white"></div> */}
      <div className="hero-content h-[600px] md:min-h-screen text-center text-black">
        <div className="max-w-3xl">
          <h1 className="mb-3 text-5xl md:text-7xl font-bold font-title">
            {data.mdx.frontmatter.title}
          </h1>
          <p className="mb-5 text-xl">{data.mdx.frontmatter.subhero}</p>
          <label htmlFor="consulta-modal" className="btn btn-primary">
            Consultanos
          </label>
        </div>
      </div>
    </BackgroundImage>
  )
}

const Iconstyles = tw`
  text-3xl
  inline-block 
  text-blue-800 
  no-underline
  hover:text-indigo-800
  hover:text-underline-position[above]
  text-center
  h-10
  p-3
  h-auto
  md:p-4
  rounded-2xl
  `

const SocialMedia = () => {
  return (
    <div className="flex space-x-4 justify-center ml-10 mb-10 content-center mx-auto absolute bottom-0 left-0">
      <a
        css={[Iconstyles, tw``]}
        href="https://www.instagram.com/la_petite_mort_tattoo/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram />
      </a>
      <a
        css={Iconstyles}
        href="https://www.facebook.com/LapetiteMortTattoo/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebookF />
      </a>
    </div>
  )
}

export default Hero
