import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion } from "framer-motion"

import { SectionTitle } from "./ui"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"

type DataProps = {
  allMdx: {
    nodes: {
      frontmatter: {
        title: string
        order: number
      }
      body: string
      id: string
      slug: string
    }[]
  }
  allFile: {
    nodes: ImageDataLike[]
  }
}

const Features = () => {
  const data = useStaticQuery<DataProps>(graphql`
    query FeaturesQuery {
      allMdx(
        filter: { slug: { regex: "/features/i" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          slug
          frontmatter {
            title
            order
          }
          body
          id
        }
      }
      allFile(
        filter: {
          base: { in: ["00-clock.png", "01-padlock.png", "02-compass.png"] }
        }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              width: 58
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `)

  const images = data.allFile.nodes.map((node) => getImage(node))
  console.log(images)

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-5 mx-auto">
        <SectionTitle
          title="Nuestra idea, nuestros principios"
          subtitle="Decoramos pieles desde la visión artística, el saber hacer y el
            respeto por el cuerpo y las personas"
        />

        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          {data.allMdx.nodes.map((node) => (
            <Feature
              key={node.id}
              order={node.frontmatter.order}
              title={node.frontmatter.title}
              body={node.body}
              icons={images}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

interface FeatureProps {
  title: string
  order: number
  body: string
  icons: (IGatsbyImageData | undefined)[]
}

const Feature = ({ title, order, body, icons }: FeatureProps) => {
  const icon = icons[order - 1]
  return (
    <motion.div
      initial={{ x: 200, scale: 0.6, opacity: 0.5 }}
      whileInView={{ x: 0, scale: 1, opacity: 1 }}
      viewport={{ once: false }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className="p-4 md:w-1/3 flex"
    >
      <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
        {icon && <GatsbyImage image={icon} alt="feature icon" />}
      </div>
      <div className="flex-grow pl-6">
        <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
          {title}
        </h2>
        <p className="leading-relaxed text-base">
          <MDXRenderer>{body}</MDXRenderer>
        </p>
      </div>
    </motion.div>
  )
}

export default Features
