const seoFaq = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "Quisiera tatuarme, ¿Cómo debo organizar una cita?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "<p>Si estas pensando en hacerte un tatuaje, podemos ayudarte.</p><p>Recopila todas esas <strong>ideas</strong> que te inspiran y háznoslas llegar. Sería ideal que pudieras venir a discutirlas en persona, el equipo del estudio esta a tu disposición para ayudarte a darle forma y redirigir el proyecto.</p><p>En algunos casos es necesario concertar una cita con el tatuador para tomar medidas o estudiar la zona a tatuar. Estas <strong>consultas son gratuitas</strong>, sin embargo para fijar una fecha y comenzar a trabajar en el diseño de tu tattoo es necesario que reserves tiempo en nuestra agenda y para ello requerimos el <strong>pago de un depósito</strong>.</p>",
      },
    },
    {
      "@type": "Question",
      name: "¿Puedo reservar mi cita por teléfono o mensaje electrónico?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Solo para tatuajes pequeños. No cerramos citas por teléfono para tatuajes de gran envergadura dado que requerimos de consulta previa para aclarar vuestras ideas. Requerimos también de un depósito para reservar tiempo en nuestra agenda. Si vives lejos o no es posible que te acerques al estudio durante nuestro horario de apertura envíanos un e-mail y te facilitaremos un método de pago para formalizarla.",
      },
    },
    {
      "@type": "Question",
      name: "Quiero tatuarme y no se qué me va a costar ( $€ … ? )",
      acceptedAnswer: {
        "@type": "Answer",
        text: "<p>Pese a que podemos darte una orientación aproximada del coste de tu tatuaje por teléfono o mensaje electrónico; difícilmente podemos darte un presupuesto sin estudiar y hablar antes acerca de tu proyecto. </p><p> Lo mejor que puedes hacer es visitarnos y te orientaremos sobre tus ideas , diseño, localización en tu cuerpo. Y así poder aconsejarte sobre las mejores opciones de las que disponemos. </p><p> El precio de partida o “precio mínimo” para tatuarte es de 60€. Salvo que puntualmente puedas acogerte a alguna de nuestras promociones especiales.</p>",
      },
    },
    {
      "@type": "Question",
      name: "¿Por qué es necesario dejar depósito? ¿Cuanto debo dejar?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Los depósitos son necesarios porque las cancelaciones resultan en mucho tiempo perdido. Cuando se formaliza una cita el tatuador se compromete con el trabajo aceptado y se emplea mucho tiempo en la preparación del diseño . El tiempo reservado en la agenda es exclusivo para el cliente que lo reserva, por lo que al cancelar en el último momento imposibilita poder ceder este espacio a otro cliente. El depósito se descuenta al finalizar el trabajo.",
      },
    },
    {
      "@type": "Question",
      name: "¿Cuál és la edad mínima para tatuarse?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "<p>La edad mínima para hacerte un tatuaje en nuestro estudio es de 18 años. Trae siempre un documento de identidad para poder coger una cita para un tatuaje. </p><p> Menores A PARTIR DE LOS 16 años pueden coger una cita para tatuaje acompañados por un padre o tutor legar. Ambos deben traer un documento de identidad que demuestra la relación entre el menor y el padre/tutor legal. </p>",
      },
    },
    {
      "@type": "Question",
      name: "Tengo una idea general para un diseño. ¿Podréis con esa idea prepararme un diseño?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "<p><strong>Claro que si</strong>! Traete fotos, arte o cualquier otro tipo de material de referencia porque nos será muy útil para poder entender mejor tus ideas. La mayoría de nuestros clientes vienen con una idea general a su primera consulta y verás que nuestro equipo esta a tu disposición para crear desde tu idea tu próximo tatuaje. </p><p> En caso de preparar tu propio diseño ten en cuenta que existe la posibilidad de que debamos modificarlo para hacerlo tatuable.</p>",
      },
    },
  ],
}

export default seoFaq
