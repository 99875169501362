import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { SectionTitle } from "./ui"

interface Node {
  frontmatter: {
    title: string
  }
  body: string
  id: string
  slug: string
}

type DataProps = {
  allMdx: {
    nodes: Node[]
  }
}

const Faqs = () => {
  const data = useStaticQuery<DataProps>(graphql`
    query FaqsQuery {
      allMdx(
        filter: { slug: { regex: "/faqs/i" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          slug
          frontmatter {
            title
          }
          body
          id
        }
      }
    }
  `)
  return (
    <div className="container mx-auto max-w-2xl">
      <SectionTitle
        title="Preguntas frecuentes"
        subtitle="Preguntas frecuentes sobre nuestros servicios"
      />
      {data.allMdx.nodes.map((node) => (
        <Faq node={node} key={node.id} />
      ))}
    </div>
  )
}

const Faq = ({ node }: { node: Node }) => {
  return (
    <div
      tabIndex={0}
      className="collapse collapse-arrow border border-base-300 rounded-box"
    >
      <div className="collapse-title text-xl font-medium">
        {node.frontmatter.title}
      </div>
      <div className="collapse-content">
        <MDXRenderer>{node.body}</MDXRenderer>
      </div>
    </div>
  )
}

export default Faqs
