import React from "react"

import { BiConversation } from "react-icons/bi"

const Icon = () => {
  return (
    <label
      htmlFor="consulta-modal"
      className="z-50 fixed bottom-8 right-1 md:right-8 w-12 h-12 rounded-full bg-green-500 text-white font-bold flex items-center justify-center hover:bg-green-800 cursor-pointer"
    >
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>

      <BiConversation />
    </label>
  )
}

export default Icon
