import React from "react" // lint:ignore-line
import tw, { css } from "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import { motion } from "framer-motion"

import { SectionTitle } from "./ui"

type DataProps = {
  allInstagramContent: {
    edges: {
      node: {
        localImage: ImageDataLike

        id: string
        caption: string
        permalink: string
      }
    }[]
  }
}

const Instagram = () => {
  const data = useStaticQuery<DataProps>(graphql`
    query InstagramQuery {
      allInstagramContent(limit: 9) {
        edges {
          node {
            localImage {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            id
            caption
            permalink
          }
        }
      }
    }
  `)
  return (
    <div
      css={[
        tw`p-4 mt-12`,
        css`
          background-color: rgba(72, 42, 53, 0.76);
        `,
      ]}
    >
      <div className="container mx-auto max-w-4xl p-4">
        <SectionTitle
          title="Últimos trabajos"
          subtitle="Visita nuestro Instagram para ver todo nuestro trabajo"
          dark={true}
        />

        <div className="hidden md:block">
          <ImageGrid data={data} />
        </div>
        <div className="block md:hidden">
          <ImageCarrousel data={data} />
        </div>
      </div>
    </div>
  )
}

const ImageGrid = ({ data }: { data: DataProps }) => (
  <div className="grid gird-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:mid-cols-4 2xl:mid-cols-5 gap-8">
    {data.allInstagramContent.edges.map(({ node }) => (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: false }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        key={node.id}
      >
        <Card
          permalink={node.permalink}
          caption={node.caption}
          image={getImage(node.localImage)}
        />
      </motion.div>
    ))}
  </div>
)

const ImageCarrousel = ({ data }: { data: DataProps }) => (
  <>
    <div className="carousel carousel-center rounded-box w-full">
      {data.allInstagramContent.edges.map(({ node }) => {
        const image = getImage(node.localImage)
        if (!image) {
          return null
        }
        return (
          <a
            href={node.permalink}
            target="_blank"
            rel="noopener noreferrer"
            className="carousel-item p-4 w-3/4"
            key={node.id}
          >
            <div id={node.id} className="">
              <GatsbyImage image={image} alt={node.caption} />
            </div>
          </a>
        )
      })}
    </div>
    <div className="flex justify-center w-full py-2 gap-2">
      {data.allInstagramContent.edges.map(({ node }, i) => (
        <a key={node.id} href={`#${node.id}`} className="btn btn-xs">
          {i}
        </a>
      ))}
    </div>
  </>
)

interface CardProps {
  permalink: string
  caption: string
  image: IGatsbyImageData | undefined
}

const Card = ({ permalink, caption, image }: CardProps) => (
  <a
    className="relative block bg-black group"
    href={permalink}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className="p-2 inset-0 w-full h-full transition-opacity opacity-100 group-hover:opacity-50">
      {image && <GatsbyImage image={image} alt={caption} />}
    </div>
    <div className="absolute inset-0 p-8">
      <div className="mt-3">
        <div className="z-10 transition-all transform translate-y-8 opacity-0 group-hover:opacity-100 group-hover:translate-y-0">
          <p className="text-sm text-white">{caption}</p>
        </div>
      </div>
    </div>
  </a>
)

export default Instagram
