import React from "react"

import { useSiteMetadata } from "../hooks/use-site-metadata"
import seoFaq from "../seo/faq"

interface Props {
  title?: string
  description?: string
  pathname?: string
  children?: React.ReactNode
}

const SEO = ({ title, description, pathname, children }: Props) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    keywords,
  } = useSiteMetadata()
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    keywords,
  }
  return (
    <>
      <html lang="es" />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="image" content={seo.image} />
      <meta property="og:url" content={seo.url} key="og:url" />
      <meta property="og:title" content={seo.title} key="og:title" />
      <meta
        property="og:description"
        content={seo.description}
        key="og:description"
      />
      <meta property="og:image" content={seo.image} key="og:image" />
      <link rel="canonical" href={seo.url} />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <script type="application/ld+json">{JSON.stringify(seoFaq)}</script>

      <link
        rel="icon"
        href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👺</text></svg>"
      />
      {children}
    </>
  )
}

export default SEO
